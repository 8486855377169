.ModalAddUser {
    margin-bottom: 0.5rem;
    position: relative;
    .label {
        color: rgba(0, 0, 0, 0.85);
        margin-bottom: 0.25rem;
    }
    .accessSpinner {
        top: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(255, 255, 255, 0.8);
        z-index: 1;
        .ant-spin {
            position: relative;
            z-index: 2;
        }
    }
    .edit-tag {
        margin-bottom: 0.5rem;
    }
    .site-tag-plus {
        margin-bottom: 0.5rem;
    }
}

.addUserInput {
    display: flex;
    button {
        margin-left: 0.5rem;
    }
    margin-bottom: 0.5rem;
}

.accessMessage {
    margin-top: 0.5rem;
}

.notifyUser {
    margin: 0.5rem 0 0.5rem 0;
}

.addUserModal {
    position: relative;
    .accessSpinner {
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(255, 255, 255, 0.8);
        z-index: 1;
        .ant-spin {
            position: relative;
            z-index: 2;
        }
    }
    .section {
        padding: 0.5rem 0.5rem 1rem 0.5rem;
        border-bottom: 1px solid #f0f0f0;
    }
}

.AccessDrawer {
    .addUser {
        display: flex;
        margin-bottom: 0.5rem;
        input {
            margin-right: 0.5rem;
        }
    }
    .section {
        margin-bottom: 1rem;
        .manageAccessSection {
            font-weight: 500;
            margin-bottom: 0.75rem;
        }
    }
}
