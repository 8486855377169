.ModalMissingEmail {
    margin-bottom: 0.5rem;
    position: relative;

    .updateSpinner {
        top: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(255, 255, 255, 0.8);
        z-index: 1;
        .ant-spin {
            position: relative;
            z-index: 2;
        }
    }
}
