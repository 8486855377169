.Home {
    min-height: 100vh;
    .ant-layout-header {
        padding: 0 3rem;
    }
    .ant-tabs-tab {
        margin: 0 1rem;
    }
    .ant-tabs-ink-bar {
        left: 1rem;
    }
    .copyright {
        text-align: center;
        position: relative;
    }
    .mainCard {
        height: 100%;
        margin: 8;
        min-width: 45vw;
        max-width: 70vw;
    }
    .statCard {
        min-width: 200px;
    }
    .ant-divider {
        margin: 24px 0;
    }
    .version {
        padding: 0.5rem;
        bottom: 0;
        right: 0;
        position: absolute;
    }
    .userInfoAndActions {
        display: flex;
        align-items: center;
        .jwtExpiration {
            display: flex;
            flex-direction: column;
            min-width: 120px;
            margin: 0 1rem 0 0.5rem;
            .title {
                color: white;
                line-height: 16px;
                font-size: 15px;
            }
            .time {
                color: white;
                line-height: 14px;
                font-size: 12px;
                opacity: 0.8;
                margin-top: 0.2rem;
            }
        }
        .user {
            display: flex;
            align-items: center;
            .ant-avatar {
                margin-right: 0.5rem;
            }
            .userInfo {
                display: flex;
                flex-direction: column;
                margin-right: 1rem;
                height: 100%;
                .userName {
                    color: white;
                    line-height: 16px;
                    font-size: 15px;
                }
                .userId {
                    color: white;
                    line-height: 14px;
                    font-size: 12px;
                    opacity: 0.8;
                    margin-top: 0.2rem;
                }
            }
        }
    }
}
.errorExpandable {
    margin-left: -32px !important;
}
