.PermissionCell {
    transition: ease-in-out 0.2s;
    border-radius: 0.25rem;
    overflow: hidden;
    :hover {
        background: #f5f5f5;
        transition: ease-in-out 0.2s;
    }
    .cell {
        padding: 0.5rem;
        display: flex;
        cursor: pointer;
        &.active {
            background: #f5f5f5;
            margin: 0;
            border-bottom: solid 1px #d9d9d9;
        }
        .userInfo {
            width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            display: flex;
            align-items: center;
            .userMeta {
                display: flex;
                flex-direction: column;
                margin-left: 0.5rem;
                width: 100%;
                .userName {
                    line-height: 16px;
                    font-size: 15px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    width: 270px;
                }
                .userId {
                    line-height: 14px;
                    font-size: 12px;
                    opacity: 0.8;
                    margin-top: 0.2rem;
                }
            }
        }
    }
    .accessManagement {
        padding: 0.75rem;
        margin-bottom: 0.5rem;
        background: #f5f5f5;
    }
    .submit {
        padding-top: 0.5rem;
        display: flex;
        justify-content: flex-end;
    }
    .accessSpinner {
        padding: 0.25rem;
    }
}
