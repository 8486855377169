.Login {
    background-color: #fafafa;
    height: 100vh;
    width: 100hw;
    display: flex;
    justify-content: center;
    align-items: center;
    .card {
        padding: 0 1rem;
        box-sizing: border-box;
        min-height: 650px;
        max-width: 450px;
        width: 100%;
        background-color: #fff;
        box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);
        border-radius: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        & > div {
            width: 100%;
        }
    }
    .loginTab {
        width: 100%;
        &.disable {
            filter: grayscale(1);
            pointer-events: none;
        }
    }
    .container {
        position: relative;
    }
    .title {
        width: 100%;
        text-align: center;
        margin-top: 2rem;
        margin-bottom: 1.5rem;

        .logo {
            padding-right: 0.5rem;
        }
    }
    .countryWrap {
        width: 100%;
        padding: 0.5rem 0.5rem 0 0.5rem;
        & > div {
            width: 100%;
        }
    }
}
