.TabContentLoginVP {
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    .countryWrap {
        width: 100%;
        padding: 0.5rem 0.5rem 0 0.5rem;
        & > div {
            width: 100%;
        }
    }
    .container {
        position: relative;
    }
    .selectCountryTooltip {
        width: 100%;
        height: 180px;
        top: 5.5rem;
        position: absolute;
        z-index: 1;
    }
}
