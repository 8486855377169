.IndexDescription {
    position: relative;
    width: 100%;
    .loadingDocumentSpinner {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(255, 255, 255, 0.8);
        z-index: 1;
        .ant-spin {
            position: relative;
            z-index: 2;
        }
    }
    .disabledLink {
        opacity: 0.8;
        cursor: pointer;
        width: auto;
        float: left;
    }
    .noAccess {
        opacity: 0.8;
    }
    .actions {
        & > button {
            margin-right: 0.5rem;
        }
    }
}