.TabContentLoginEIdEasy {
    .countryWrap {
        width: 100%;
        padding: 0.5rem 0.5rem 0 0.5rem;
        & > div {
            width: 100%;
        }
    }
    .container {
        display: flex;
        justify-content: center;
        padding: 0.5rem;
        button {
            margin: 1rem 0;
        }
    }
    .widgetHolder {
        width: 400px;
        iframe {
            height: 100% !important;
            min-height: 250px;
        }
    }
}
