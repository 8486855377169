.IndexDrawerForm {
    .footer {
        text-align: right;
        .ant-btn.cancel {
            margin-right: 0.5rem;
        }
    }
    .DocumentUpload {
        width: 100%;
        overflow: hidden;
        margin-bottom: 1rem;
        .ant-upload-select {
            width: 100%;
            border: dashed 1px #d9d9d9;
            .ant-upload {
                cursor: pointer;
                width: 100%;
                height: 100%;
                height: 5rem;
                display: flex;
                justify-content: center;
                & > * {
                    align-self: center;
                }
                .UploadIcon {
                    margin-right: 0.25rem;
                }
            }
        }
    }
}
