.StorageCollapse {
    .storageCollapseHeader {
        font-weight: bold;
        font-size: 16px;
        color: rgb(51, 51, 51);
    }
    .storageSpinner {
        height: 100px;
        position: relative;
    }
    .actions {
        padding: 1rem 1.2rem !important;
        .ant-btn {
            transform: scale(1.4);
        }
    }
}
