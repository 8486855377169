.LoaderWithBackdrop {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;

    .ant-spin {
        i {
            background-color: var(--color-primary);
        }
        color: var(--color-primary);
    }
}
