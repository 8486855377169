.IndexDescription {
    position: relative;
    width: 100%;
    .loadingDocumentSpinner {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(255, 255, 255, 0.8);
        z-index: 1;
        .ant-spin {
            position: relative;
            z-index: 2;
        }
    }
    .disabledLink {
        opacity: 0.8;
        cursor: pointer;
        width: auto;
        float: left;
    }
    .noAccess {
        opacity: 0.8;
    }
    .actions {
        & > button {
            margin-right: 0.5rem;
        }
    }
    .signStatus {
        padding: 2px 4px;
        margin-left: 0.5rem;
        &.Requested {
            background: #fffbe6;
            color: #faad14;
            border: 1px solid #faad14;
        }
        &.Signed {
            background: #9fa09e;
            color: #52c41a;
            border: 1px solid #52c41a;
        }
    }
    .ant-btn.requestAccess {
        margin-left: 1rem;
    }
    .storageCollapse {
        font-weight: bold;
        font-size: 16px;
        color: rgb(51, 51, 51);
    }
}
