.TabContentLoginDokobit {
    .countryWrap {
        width: 100%;
        padding: 0.5rem 0.5rem 0 0.5rem;
        & > div {
            width: 100%;
        }
    }
    .container {
        position: relative;
        min-height: 275px;
    }
    .selectCountryTooltip {
        width: 100%;
        height: 180px;
        top: 5.5rem;
        position: absolute;
        z-index: 1;
    }
}
