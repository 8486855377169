.userInfoAndActions {
    display: flex;
    align-items: center;
    .jwtExpiration {
        display: flex;
        flex-direction: column;
        min-width: 120px;
        margin: 0 1rem 0 0.5rem;
        .title {
            color: white;
            line-height: 16px;
            font-size: 15px;
        }
        .time {
            color: white;
            line-height: 14px;
            font-size: 12px;
            opacity: 0.8;
            margin-top: 0.2rem;
        }
    }
    .user {
        display: flex;
        align-items: center;
        .ant-avatar {
            margin-right: 0.5rem;
        }
        .userInfo {
            display: flex;
            flex-direction: column;
            margin-right: 1rem;
            height: 100%;
            width: 100%;
            .userMeta {
                width: 100%;
                overflow: hidden;
                .userName {
                    color: white;
                    line-height: 16px;
                    font-size: 15px;
                    text-overflow: ellipsis;
                    width: 270px;
                }
                .userId {
                    color: white;
                    line-height: 14px;
                    font-size: 12px;
                    opacity: 0.8;
                    margin-top: 0.2rem;
                }
            }
        }
    }
}
