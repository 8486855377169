.FindIndexTab {
    .resultsTotal {
        width: 100%;
        padding: 0.5rem;
        text-align: right;
        font-size: 16px;
        span {
            font-weight: bold;
            margin-right: 0.25rem;
        }
    }
}
